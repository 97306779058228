<template>
  <!-- form -->
  <validation-observer ref="registerForm" #default="{ invalid }">
    <p class="text-center">
      <b-spinner
        v-if="carregamentoDadosApi"
        small
        label="Carregando"
        class="text-primary"
      />
    </p>

    <b-form
      v-if="!carregamentoDadosApi"
      class="auth-register-form mt-2"
      @submit.prevent="register"
    >
      <b-row>
        <b-col cols="12" xl="6">
          <b-card class="custom-card" title="Dados da Audição">
            <b-form-group label="Data Inicio" label-for="inicio">
              <validation-provider #default="{ errors }" name="inicio" vid="inicio" rules="required">
                <flat-pickr
                  id="inicio"
                  v-model="campanhaInicio"
                  :config="configCampanhaData"
                  :state="errors.length > 0 ? false:null"
                  class="form-control"
                  placeholder="DD/MM/AAAA"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Data Fim" label-for="fim">
              <validation-provider #default="{ errors }" name="fim" vid="fim" rules="required">
                    <flat-pickr
                      id="fim"
                      v-model="campanhaFim"
                      :config="configCampanhaData"
                      :state="errors.length > 0 ? false:null"
                      class="form-control"
                      placeholder="DD/MM/AAAA"
                    />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Descrição" label-for="descricao">
              <validation-provider
                #default="{ errors }"
                name="descricao"
                vid="descricao"
                rules="required"
              >
                <small class="text-danger">{{ errors[0] }}</small>
                <b-form-textarea
                  id="audicaoDescricao"
                  v-model="campanha.descricao"
                  placeholder="Descrição da Audição"
                  rows="10"
                  no-resize
                />
              </validation-provider>
            </b-form-group>
            <b-button
              class="mt-5 position-absolute top-0 start-0"
              variant="primary"
              type="submit"
              :disabled="invalid || respostaApi"
            >
              Salvar
              <b-spinner v-if="respostaApi" small label="Carregando" />
            </b-button>
          </b-card>
        </b-col>

        <!-- Lista de Hits -->
        
        <b-col cols="12" xl="6">
          <b-card class="custom-card">
              <b-row class="justify-content-between">
                <h4 class="ml-1">Lista de Hits</h4>

                <b-media no-body class="mr-2 mb-2">
                  <b-media-aside class="mr-1" >
                    <b-avatar size="34" variant="light-primary">
                      <feather-icon size="18" icon="MusicIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-medium-1 font-weight-bolder mb-0">
                      {{ qtdHitsCampanha }}
                    </h4>
                    <b-card-text class="font-medium-1 mb-0 text-dark">
                      Composições
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-row>
              <b-table ref="refHitsListTable" class="position-relative custom-table" :items="hitsCampanha" responsive :fields="tableColumns" primary-key="data"
              :sort-by.sync="sortBy" show-empty empty-text="Sem resultados" :sort-desc.sync="isSortDirDesc"
              >      
                <template #cell(id)="data">
                  <b-media vertical-align="center">
                    <b-link
                      :to="{ name: 'usuarios-compositor-editar-hit', params: { id: data.item.id } }"
                      class="font-weight-bold d-block text-nowrap"
                    >#{{ data.item.id }}</b-link>
                  </b-media>
                </template>

                <template #cell(nomeComposicao)="data">
                  <div class="second-column-cell">
                    {{ data.item.nomeComposicao }}
                  </div>
                </template>
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <b-col cols="12" sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                  </b-col>

                  <b-col cols="12" sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                  <b-pagination v-model="currentPage" :total-rows="qtdHitsCampanha" :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @input="mudarPagina"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                  </b-col>
                </b-row>
              </div>
          </b-card>
        </b-col>

      </b-row>
    </b-form>
  </validation-observer>
</template>

  <script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BSpinner,
  BFormFile,
  BAlert,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BCardText,
  BTable,
  BPagination,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { required } from "@validations";

import useJwt from "@/auth/jwt/useJwt";

import DisableAutocomplete from "vue-disable-autocomplete";

import { TheMask } from "vue-the-mask";

// ERROS DOS CAMPOS
import { localize } from "vee-validate";

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      inicio: {
        required: "A data é obrigatória.",
      },
      fim: {
        required: "A data é obrigatória.",
      },
      descricao: {
        required: "A descrição é obrigatória.",
      },
    },
  },
});
import moment from "moment";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

// Sem foto

export default {
  components: {
    BSpinner,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BTable,
    BPagination,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BLink,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // autocomplete off
    DisableAutocomplete,
    TheMask,
    flatPickr,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      respostaApi: false,
      carregamentoDadosApi: false,
      primeiraAtualizacao: true,
      hitsCampanha: [],
      qtdHitsCampanha: 0,
      campanhaInicio: null,
      campanhaFim: null,
      refHitsListTable: null,
      tableColumns: [
        { key: "id", label: "ID", sortable: true },
        { key: "nomeComposicao", label: "Composição", sortable: true },
        ],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "data",
      isSortDirDesc: true,
      configCampanhaData: {
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        allowInput: true,
        locale: Portuguese, 
      },
    };
  },
  props: {
    campanha: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  mounted() {
    this.$watch(
      "campanha",
      (newVal, oldVal) => {
        if (!this.primeiraAtualizacao) {
          this.campanhaListaHitsPorCampanha();
          this.ajustarFormatoDatas()
        } else {
          this.primeiraAtualizacao = false;
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    campanhaListaHitsPorCampanha() {
      this.carregamentoApi = true;
      const paginaAtual = this.currentPage - 1;
      useJwt
      .campanhaListaHitsPorCampanha({
        campanhaId: this.campanha.id,
        page: paginaAtual,
        size: this.perPage,
        sort: "ASC"
      })
      .then((response) => {
        this.qtdHitsCampanha = response.data.totalizadorHits;
        this.hitsCampanha = response.data.hitsCampanha.content;
      })
      .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.carregamentoApi = false;
        });
    },
    formatarData(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    ajustarFormatoDatas() {
      this.campanhaInicio = this.formatarData(this.campanha.inicio);
      this.campanhaFim = this.formatarData(this.campanha.fim);
    },
    
    register() {
       if (
          this.campanhaFim < this.campanhaInicio
       ) {
         this.$swal({
           title: "Erro!",
           text: "Informe uma data válida.",
           icon: "error",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: false,
         });
         return;
       }
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.respostaApi = true; 
          useJwt
            .campanhaEditar({
              id: this.campanha.id,
              descricao: this.campanha.descricao,
              inicio: this.campanhaInicio,
              fim: this.campanhaFim,
            })
            .then((response) => {
              this.$swal({
                title: "Informações Salvas!",
                text: "As informações foram atualizadas com sucesso.",
                icon: "success",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.go();
                } else {
                  this.$router.go();
                }
              });
            })
            .catch((error) => {
              this.$swal({
                title: "Erro!",
                text: error.response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

              //this.$refs.registerForm.setErrors(error.response.data.error)
            })
            .finally(() => {
              this.respostaApi = false;
            });
        }
      });
    },
    mudarPagina() {
        this.campanhaListaHitsPorCampanha();
      }
  },
};
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  .custom-card {
    height: 93%;
  }
  
  .custom-table .second-column-cell {
    width: 350px; 
  }
  </style>